// import ReactMarkdown from "react-markdown";
import "github-markdown-css/github-markdown.css";
// import remarkGfm from "remark-gfm";
// import remarkBreaks from "remark-breaks";
// import rehypeRaw from "rehype-raw";
// import rehypeRewrite from "rehype-rewrite";
// import remarkUnwrapImages from "remark-unwrap-images";
import { observer } from "mobx-react-lite";
import React from "react";

export const MarkdownView = React.memo(observer(({ message, quoteMsg }: { message: string, quoteMsg: any }) => {
  return (
    <div className="markdown-body !bg-transparent !text-[#1f2328]">

      <div className="markdown-body-text">
        {
          quoteMsg && <div className="top-text">
            <div className="top-text-name">{quoteMsg?.sender}</div>
            <div className="top-text-content">{quoteMsg?.msg}</div>
          </div>
        }

        <div>{message}</div>
      </div>
      {/* <ReactMarkdown
        components={{
          em: ({ ...props }) => <span>*{props.children}*</span>, // 禁用斜体，保持原样
        }}
        rehypePlugins={[rehypeRaw, [rehypeRewrite]]}
        remarkPlugins={[remarkBreaks, remarkUnwrapImages]}
      >
        {message}
      </ReactMarkdown> */}
    </div>
  );
}));
