import ky from "ky";
import type { IAssistantItem } from "@/store/assistant";
import type { IThreadItem } from "@/store/thread";
import type { ICreateMassageJson } from "@/store/message";
import type {
  IBatchFileItem,
  IBatchTaskItem,
  MarkBatchTasks,
} from "@/store/batch";
import cookie from "react-cookies";
import { message } from "antd";
import { getHashParams } from "@/utils";
const prefixUrl = localStorage.getItem("prefixUrl");
const kyServe = ky.create({
  prefixUrl: prefixUrl ? prefixUrl : "/api/v2/",
  timeout: 600000,
  hooks: {
    beforeRequest: [
      (request) => {
        const params = getHashParams(window.location.href);
        if (params?.["key"]) {
          request.headers.set("Authorization", `Bearer ${params["key"]}`);
        }
        request.headers.set(
          "OpenAI-Organization",
          params?.["org"] ?? localStorage.getItem("organization")
        );
      },
    ],
    afterResponse: [
      (_, __, response) => {
        const { status, statusText, url } = response;
        if (status === 403) {
          window.location.href = "/#/login";
        }
        if ((status >= 300 || status < 200) && status !== 403) {
          message.error(`错误: ${status} - ${statusText} - ${url}`);
        }
      },
    ],
  },
});

const userServe = ky.create({
  prefixUrl: "/myuser/api/v1/",
  timeout: 600000,
  hooks: {
    beforeRequest: [
      (request) => {
        const params = getHashParams(window.location.href);
        // if (params?.["key"]) {
        //   request.headers.set("Authorization", `Bearer ${params["key"]}`);
        // }
        request.headers.set(
          "OpenAI-Organization",
          params?.["org"] ?? localStorage.getItem("organization")
        );
      },
    ],
    afterResponse: [
      (_, __, response) => {
        const { status, statusText, url } = response;
        if (status === 403) {
          window.location.href = "/#/login";
        }
        if ((status >= 300 || status < 200) && status !== 403) {
          message.error(`错误: ${status} - ${statusText} - ${url}`);
        }
      },
    ],
  },
});
const msgServe = ky.create({
  prefixUrl: "/message/api/msg/",
  timeout: 600000,
  hooks: {
    beforeRequest: [
      (request) => {
        const params = getHashParams(window.location.href);
        // if (params?.["key"]) {
        //   request.headers.set("Authorization", `Bearer ${params["key"]}`);
        // }
        request.headers.set(
          "OpenAI-Organization",
          params?.["org"] ?? localStorage.getItem("organization")
        );
      },
    ],
    afterResponse: [
      (a, b, response) => {
        const { status, statusText, url } = response;
        if (status === 403) {
          window.location.href = "/#/login";
        }
        if ((status >= 300 || status < 200) && status !== 403) {
          message.error(`错误: ${status} - ${statusText} - ${url}`);
        }
      },
    ],
  },
});
const msgConfigServe = ky.create({
  prefixUrl: "/message/api/",
  timeout: 600000,
  hooks: {
    beforeRequest: [
      (request) => {
        const params = getHashParams(window.location.href);
        // if (params?.["key"]) {
        //   request.headers.set("Authorization", `Bearer ${params["key"]}`);
        // }
        request.headers.set(
          "OpenAI-Organization",
          params?.["org"] ?? localStorage.getItem("organization")
        );
      },
    ],
    afterResponse: [
      (a, b, response) => {
        const { status, statusText, url } = response;
        if (status === 403) {
          window.location.href = "/#/login";
        }
        if ((status >= 300 || status < 200) && status !== 403) {
          message.error(`错误: ${status} - ${statusText} - ${url}`);
        }
      },
    ],
  },
});
class API {
  /** 获取assistant 列表 */
  getAssistants() {
    return kyServe.get("assistants").json<{
      data: IAssistantItem[];
    }>();
  }

  /** 创建assistant */
  createAssistant(json: IAssistantItem) {
    return kyServe.post("assistants", { json }).json();
  }

  /** 更新assistant */
  updateAssistant(json: Partial<IAssistantItem>) {
    return kyServe
      .post(`assistants/${json.id}`, {
        json,
      })
      .json();
  }
  /** 删除assistant */
  deleteAssistant(id: string) {
    return kyServe.delete(`assistants/${id}`).json();
  }

  /** 获取模型列表 */
  getModelList() {
    return kyServe.get("models").json<{
      data: Array<{ id: string }>;
    }>();
  }

  /** 获取messages */
  getMessages(threadId: string, replyMessageRole: string) {
    // threads/${threadId}/messages
    return msgServe
      .post(`selectConversation`, {
        json: {
          conversationId: threadId,
          replyMessageRole
        },
      })
      .json()
  }
  /** 获取messages */
  getLastMessagePre(threadId: string) {
    return msgServe
      .get(`getLastMessagePre`, {
        searchParams: {
          conversationId: threadId,
        },
      })
      .json()
  }
  /** 创建Messages **/
  createMessage(json: ICreateMassageJson) {
    return kyServe
      .post(`threads/${json.thread_id}/messages`, {
        json: {
          metadata: {
            assistant_id: json.assistant_id,
            assistant_name: json.assistant_name,
            ...json.metaData,
          },
          role: json.role,
          file_ids: json.file_ids,
          content: json.content,
        },
      })
      .json<{
        data: Array<{
          id: string;
          role: string;
          assistant_id: string;
          thread_id: string;
          content: Array<{
            text: Array<{
              value: string;
            }>;
          }>;
        }>;
      }>();
  }

  /** 获取threads */
  getThreads(limit) {
    return kyServe
      .get(`threads?limit=${limit}`)
      .json<{ data: IThreadItem[] }>();
  }
  /** 根据条件搜索threads */
  getThreadsSearch({
    source,
    assistant_id,
    thumb,
    start_time,
    end_time,
    conversationId,
    messageId,
    pageNum,
    pageSize,
    search,
    uid
  }) {
    return msgServe
      .post(`selectConversationList`, {
        json: {
          source,
          assistantId: assistant_id,
          thumbs: thumb,
          startTime: start_time,
          endTime: end_time,
          conversationId,
          messageId,
          pageNum,
          pageSize,
          search,
          uid
        },
      })
      .json<{ data: IThreadItem[] }>();
  }
  /** 获取大模型回复 */
  runThreads({ thread_id, assistant_id }) {
    return kyServe.post(`threads/${thread_id}/runs?stream=true`, {
      json: {
        assistant_id,
      },
    });
  }
  /** 创建thread */
  createThread(
    assistantId: string,
    assistantName: string,
    source: string,
    initMetaData
  ) {
    return kyServe
      .post("threads", {
        json: {
          metadata: {
            ...initMetaData,
            assistant_id: assistantId,
            assistant_name: assistantName,
            source,
          },
        },
      })
      .json();
  }
  /** 编辑thread metadata 信息*/
  updateThread(thread_id, metadata) {
    return kyServe
      .post(`threads/${thread_id}`, {
        json: {
          metadata: metadata,
        },
      })
      .json();
  }
  /** 获取编辑thread 信息**/
  getThread(thread_id) {
    return kyServe.get(`threads/${thread_id}`).json();
  }

  /** 删除thread */
  deleteThread(threadId: string) {
    return kyServe.delete(`threads/${threadId}`).json();
  }
  /** 上传批量对话文件 */
  uploadBatchFile(file: File, assistant_id: string) {
    const formData = new FormData();
    formData.append("file", file);
    return kyServe
      .post(`batch/upload-query`, {
        searchParams: { assistant_id },
        body: formData,
      })
      .json();
  }
  /** 获取批量对话文件列表 */
  getBatchFile(assistant_id) {
    return kyServe
      .get(`batch/files`, {
        searchParams: {
          assistant_id,
        },
      })
      .json<IBatchFileItem[]>();
  }
  /** 删除批量对话文件 */
  deleteBatchFile(file_id: number, assistant_id: string) {
    return kyServe
      .delete(`batch/files`, {
        json: {
          file_id,
          assistant_id,
        },
      })
      .json();
  }
  /** 创建批量对话任务 */
  createBatchTasks({
    file_id,
    assistant_id,
    llm_config,
    meta_config,
    process_config,
    begin_threads,
    end_threads,
    need_llm,
    task_name,
    one_by_one,
  }) {
    return kyServe
      .post(`batch/tasks`, {
        json: {
          file_id,
          assistant_id,
          api_key: cookie.load("secret_key"),
          llm_config,
          meta_config,
          process_config,
          begin_threads,
          end_threads,
          need_llm,
          task_name,
          one_by_one,
        },
      })
      .json();
  }
  /** 下载批量对话文件 */
  downBatchFile(file_id: number, assistant_id: string, id: number) {
    return kyServe
      .get(`batch/files/${file_id}`, {
        searchParams: {
          assistant_id,
          id,
        },
      })
      .blob();
  }
  /** 获取对话信息 */
  getChatItem(thread_id: string) {
    return kyServe
      .get(`debug/batch/messages`, {
        searchParams: {
          thread_id,
        },
      })
      .json();
  }
  /** 获取批量对话任务列表 */
  getBatchTasks(assistant_id) {
    return kyServe
      .get(`batch/tasks`, {
        searchParams: {
          assistant_id,
        },
      })
      .json<IBatchTaskItem[]>();
  }

  /** 批量对话消息回复点踩功能 */
  markBatchTasks(data: MarkBatchTasks) {
    const { taskid, key, value } = data;
    return kyServe
      .put(`batch/tasks/${taskid}`, {
        json: {
          key,
          value,
        },
      })
      .json();
  }
  /** 获取批量对话发布任务时的llm-config */
  getllmconfig() {
    return kyServe.get(`batch/llm-config`).json();
  }
  /** 获取批量对话任务列表 */
  getBatchTasksOutput(
    task_id: string,
    page: number,
    search: string = "",
    assistant_id: string,
    page_size: number = 20
  ) {
    return kyServe
      .post(`batch/tasks/output`, {
        json: { task_id, search, assistant_id },
        searchParams: {
          page: page + "",
          page_size: page_size + "",
        },
      })
      .json<{
        data: {
          current_page: number;
          page_size: number;
          total_threads: number;
          // eslint-disable-next-line
          summary: {
            total_thread: number;
          };
          thread_infos: any[];
          tasks: Array<
            Array<{
              base_data: {
                query: { content: string; msg_id: string };
                expected: { content: string; msg_id: string };
              };
              eval_data: { llm_result: string; status: string };
              llm_data: { content: string; elapsed: number; thread_id: string };
            }>
          >;
        };
      }>();
  }
  /** 删除批量对话任务 */
  deleteBatchTasks(task_id: number, assistant_id: string) {
    return kyServe
      .delete(`batch/tasks`, {
        searchParams: {
          assistant_id,
          task_id,
          api_key: cookie.load("secret_key"),
        },
      })
      .json();
  }
  /** 下载批量对话任务 */
  downloadBatchTasks(task_id: string, assistant_id: string) {
    return kyServe
      .get(`batch/tasks/download`, {
        searchParams: {
          assistant_id,
          task_id,
        },
      })
      .blob();
  }

  /** 获取知识库文件 */
  getFile(limit) {
    return kyServe
      .get(`files`, {
        searchParams: { limit },
      })
      .json();
  }

  getFileItem(file_id) {
    return kyServe.get(`files/${file_id}`).json();
  }
  /** 删除知识库文件 */
  deleteFile(id) {
    return kyServe
      .delete(`ses/file`, {
        searchParams: { id },
      })
      .json();
  }
  /** 上传知识库文件 */
  uploadFile(file, metadata, embeddings) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("purpose", "assistants");
    formData.append("embeddings", embeddings);
    if (metadata) {
      metadata = JSON.parse(metadata);
      for (const k of Object.keys(metadata)) {
        formData.append(k, metadata[k]);
      }
    }
    return kyServe.post(`files`, { body: formData }).json();
  }
  /** 获取助手工具 */
  getTools() {
    return kyServe.get("tools").json();
  }
  /** 获取消息metadata信息 */
  getMessageItemData(message_id) {
    // threads/${thread_id}/messages/${message_id}
    return msgServe
      .get(`getLogMetaData`, {
        searchParams: {
          // threadId: thread_id,
          messageId: message_id,
        },
      })
      .json();
  }
  /** 工作台回话点踩操作 */
  updateMessageItemData({ message_id, thumb }) {
    // threads/${thread_id}/messages/${message_id}
    return msgServe
      .post(`thumbs`, {
        // searchParams: {
        //   // threadId: thread_id,
        //   messageId: message_id,
        //   thumb, // 2 赞 -2踩 类型为字符串
        // },
        json: {
          messageId: message_id,
          thumbs: thumb, // 2 赞 -2踩 类型为字符串
        }
      })
      .json();
  }
  /** 登录 */
  login(username, password) {
    return kyServe
      .post(`users/${username}/login`, {
        json: {
          username,
          password,
        },
      })
      .json<{
        secret_key: {
          display_name: null;
          tag: string;
          metadata: null;
          id: string;
          object: string;
          created_at: number;
          user_id: string;
        };
        user: {
          username: string;
          display_name: string;
          metadata: null;
          id: string;
          object: string;
          created_at: number;
          is_sa: boolean;
          orgs: any[];
        };
      }>();
  }

  /** 获取知识库问法 */
  getProductQuery({ assistant_id }) {
    return kyServe
      .get(`myoracle/product_query`, {
        searchParams: {
          assistant_id,
        },
      })
      .json();
  }
  /** 添加知识库问法 */
  addProductQuery(json: {
    product_id;
    cate_id;
    product_qa_id;
    query;
    version_id;
    meta_data?: any;
  }) {
    return kyServe
      .post(`myoracle/product_query`, {
        json,
      })
      .json();
  }
  /** 删除知识库问法 */
  deleteProductQuery(id, version_id) {
    return kyServe
      .delete(`myoracle/domain/intention/product_query_base`, {
        searchParams: { id, version_id },
      })
      .json();
  }
  /** 编辑知识库问法 */
  updateProductQuery({
    product_id,
    cate_id,
    product_qa_id,
    query,
    id,
    version_id,
    query_id,
    meta_data,
  }) {
    return kyServe
      .put(`myoracle/product_query`, {
        searchParams: {
          id,
        },
        json: {
          product_id,
          cate_id,
          product_qa_id,
          query,
          version_id,
          query_id,
          meta_data,
        },
      })
      .json();
  }
  /** 获取知识库意图 */
  getProductQa({ page_num, page_size, version_id }) {
    return kyServe
      .get(`myoracle/product_qa`, {
        searchParams: {
          page_num,
          page_size,
          version_id,
        },
      })
      .json();
  }
  /** 添加知识库意图 */
  addProductQa(json: {
    cate_kb_id;
    cate_id;
    product_id;
    answer_rule;
    terms;
    version_id;
    terms_type;
  }) {
    return kyServe
      .post(`myoracle/product_qa`, {
        json,
      })
      .json();
  }
  /** 删除知识库意图 */
  deleteProductQa(id, version_id) {
    return kyServe
      .delete(`myoracle/product_qa`, {
        searchParams: { id, version_id },
      })
      .json();
  }
  /** 编辑知识库意图 */
  updateProductQa({
    cate_kb_id,
    cate_id,
    product_id,
    kb_name,
    answer_rule,
    terms,
    id,
    version_id,
    intent_id,
  }) {
    return kyServe
      .put(`myoracle/product_qa`, {
        searchParams: {
          id,
        },
        json: {
          cate_kb_id,
          cate_id,
          product_id,
          answer_rule,
          kb_name,
          terms,
          version_id,
          intent_id,
        },
      })
      .json();
  }
  /** 获取知识库回答 */
  getResponse(data: { page_num; page_size; product_qa_id; version_id }) {
    return kyServe
      .get(`myoracle/response`, {
        searchParams: data,
      })
      .json();
  }
  /** 添加知识库回答 */
  addResponse(json: {
    response;
    product_qa_id;
    kb_category;
    item_id;
    shop_name;
    attr_info;
    version_id;
  }) {
    return kyServe
      .post(`myoracle/response`, {
        json: json,
      })
      .json();
  }
  /** 删除知识库回答 */
  deleteResponse(id, version_id) {
    return kyServe
      .delete(`myoracle/domain/intention/response`, {
        searchParams: { id, version_id },
      })
      .json();
  }
  /** 编辑知识库回答 */
  updateResponse(
    json: {
      response;
      product_qa_id;
      kb_category;
      item_id;
      shop_name;
      attr_info;
      version_id;
      response_id;
      meta_data?: any;
    },
    id
  ) {
    return kyServe
      .put(`myoracle/response`, {
        searchParams: {
          id,
        },
        json: json,
      })
      .json();
  }
  /** 复制意图话术 */
  copyProductQuery(json: {
    version_id;
    intent_id;
  }) {
    return kyServe
      .post(`myoracle/product_qa/copy`, {
        json,
      })
      .json();
  }
  /** 获取知识库全量意图信息 */
  getDomain(
    kb_name,
    version_id,
    search?: string,
    terms_type?: string,
    data?: {
      shop: string;
      pid: string;
      attr_info: string;
      kb_category: string;
    }
  ) {
    return kyServe
      .post(`myoracle/domain/intention`, {
        json: {
          kb_name,
          search,
          version_id,
          terms_type,
          ...data,
        },
      })
      .json();
  }
  /** 获取知识库知识列表 */
  getKbname() {
    return kyServe.get(`myoracle/product_qa/get_kb_name`).json();
  }
  /** 删除知识库知识列表 */
  deleteKb(kb_name, version_id) {
    return kyServe
      .delete(`myoracle/domain/intention`, {
        searchParams: {
          kb_name,
          version_id: version_id ?? "",
        },
      })
      .json();
  }
  /** 导入知识库文件 */
  domainUploadFile(file, kb_name, version_id, assistant_id) {
    const formData = new FormData();
    formData.append("files", file);
    formData.append("kb_name", kb_name);
    formData.append("version_id", version_id);
    return kyServe
      .post(`myoracle/domain/intention/upload_docs`, {
        body: formData,
        searchParams: {
          assistant_id,
        },
      })
      .json();
  }
  /** 导出知识库文件 */
  downDomainFile(kb_name: string, version_id) {
    return kyServe
      .post(`myoracle/domain/intention/download_docs`, {
        searchParams: {
          kb_name,
          preview: 0,
          version_id,
        },
      })
      .blob();
  }
  /** 获取知识库文件path */
  getDocPath(kb_name, file_name, version_id) {
    return kyServe
      .post(`myoracle/domain/intention/get_doc_path`, {
        searchParams: {
          kb_name,
          file_name,
          version_id,
        },
      })
      .json();
  }
  /** 导入助手知识文件 */
  migrateDocs({ file_id, kb_name, override, assistant_id }) {
    return kyServe
      .post(`myoracle/domain/intention/migrate_docs`, {
        searchParams: {
          file_id,
          kb_name,
          override,
          assistant_id,
        },
      })
      .json();
  }
  /** 知识库问法建议 */
  querySim(id, version_id) {
    return kyServe
      .post(`myoracle/domain/detection/query_sim`, {
        searchParams: {
          id,
          version_id,
        },
      })
      .json();
  }
  /** 知识库文件同步到助手 */
  knowledge({ file_name, embeddings, metadata, version_id }) {
    const formData = new FormData();
    formData.append("file_name", file_name);
    formData.append("purpose", "assistants");
    formData.append("embeddings", embeddings);
    formData.append("version_id", version_id);
    if (metadata) {
      for (const k of Object.keys(metadata)) {
        if (k !== "version_id") formData.append(k, metadata[k]);
      }
    }
    return kyServe
      .post(`knowledge`, {
        searchParams: {
          file_name,
        },
        body: formData,
      })
      .json();
  }
  /** 知识库获取问法推荐 */
  getRecommend(prefix, data, version_id, file_id) {
    let metadata = {};
    try {
      metadata = JSON.parse(data.metadata);
    } catch (e) {
      metadata = JSON.parse("{}");
    }
    return kyServe
      .post(`knowledge/get_recommend`, {
        json: {
          metadata,
          prefix,
          file_id,
          version_id,
          assistant_id: data.assistantId,
          q_or_a: true,
        },
      })
      .json();
  }
  /** 知识库获取回复推荐 */
  getResponseRecommend(prefix, data, version_id, file_id) {
    let metadata = {};
    try {
      metadata = JSON.parse(data.metadata);
    } catch (e) {
      metadata = JSON.parse("{}");
    }
    return kyServe
      .post(`knowledge/get_recommend`, {
        json: {
          metadata,
          prefix,
          version_id,
          file_id,
          assistant_id: data.assistantId,
          q_or_a: false,
        },
      })
      .json();
  }
  /** 知识库删除建议接口 */
  deleteSuggest(kb_name, data, version_id) {
    let metadata = {};
    try {
      metadata = JSON.parse(data.metadata);
    } catch (e) {
      metadata = JSON.parse("{}");
    }
    return kyServe
      .post(`knowledge/delete_suggest`, {
        json: {
          kb_name,
          version_id,
          assistant_id: data.assistantId,
          metadata,
          q_or_a: true,
        },
      })
      .json();
  }
  /** 知识检查依据文件生成 */
  genHistory(assistant_id, metadata, version_id, kb_name) {
    const formData = new FormData();
    formData.append("assistant_id", assistant_id);
    formData.append("purpose", "assistants");
    formData.append("loader", "chat_query");
    formData.append("version_id", version_id);
    if (metadata) {
      for (const k of Object.keys(metadata)) {
        if (k !== "loader") formData.append(k, metadata[k]);
      }
    }
    return kyServe
      .post(`knowledge/gen_history`, {
        searchParams: {
          assistant_id,
          version_id,
          kb_name,
          q_or_a: true,
        },
        body: formData,
      })
      .json();
  }
  /** 知识库添加属性 */
  addProductAttr(attr_name: string, version_id) {
    return kyServe
      .post(`myoracle/product_attr`, {
        json: {
          attr_name,
          value: [],
          user_id: cookie.load("user")?.id ?? "",
          version_id,
        },
      })
      .json();
  }
  /** 知识库删除属性 */
  deleteProductAttr(id: string, version_id) {
    return kyServe
      .delete(`myoracle/product_attr`, {
        searchParams: {
          id,
          version_id,
        },
      })
      .json();
  }
  /** 知识库编辑属性 */
  updateProductAttr(id: string, attr_key: string, version_id, value) {
    return kyServe
      .put(`myoracle/product_attr`, {
        searchParams: {
          id,
        },
        json: {
          attr_name: attr_key,
          value,
          user_id: cookie.load("user")?.id ?? "",
          version_id,
        },
      })
      .json();
  }
  /** 知识库获取属性 */
  getProductAttr(page_num, page_size, version_id) {
    return kyServe
      .get(`myoracle/product_attr`, {
        searchParams: {
          page_num,
          page_size,
          user_id: cookie.load("user")?.id ?? "",
          version_id,
        },
      })
      .json();
  }
  /** 知识库添加类别 */
  addProductCate(category: string, version_id, children) {
    return kyServe
      .post(`myoracle/product_cate`, {
        json: {
          category,
          version_id,
          children,
          user_id: cookie.load("user")?.id ?? "",
        },
      })
      .json();
  }
  /** 知识库删除类别 */
  deleteProductCate(id: string, version_id) {
    return kyServe
      .delete(`myoracle/product_cate`, {
        searchParams: {
          id,
          version_id,
        },
      })
      .json();
  }
  /** 知识库编辑属性 */
  updateProductCate(id: string, category: string, version_id, children) {
    return kyServe
      .put(`myoracle/product_cate`, {
        searchParams: {
          id,
        },
        json: {
          category,
          version_id,
          children,
          user_id: cookie.load("user")?.id ?? "",
        },
      })
      .json();
  }
  /** 知识库获取类别 */
  getProductCate(page_num, page_size, version_id) {
    return kyServe
      .get(`myoracle/product_cate`, {
        searchParams: {
          page_num,
          page_size,
          version_id,
          user_id: cookie.load("user")?.id ?? "",
        },
      })
      .json();
  }
  /** 知识库添加商品 */
  addProduct(data: {
    title: string;
    page_title: string;
    item_id: string;
    price: string;
    category: string;
    properties: string;
    code: string;
    shop_id: string;
    children: any;
    version_id: string;
  }) {
    return kyServe
      .post(`myoracle/product`, {
        json: {
          ...data,
          user_id: cookie.load("user")?.id ?? "",
        },
      })
      .json();
  }
  /** 知识库删除商品 */
  deleteProduct(id: string, version_id) {
    return kyServe
      .delete(`myoracle/product`, {
        searchParams: {
          id,
          version_id,
        },
      })
      .json();
  }
  /** 知识库编辑商品 */
  updateProduct(
    id: string,
    data: {
      title: string;
      page_title: string;
      item_id: string;
      price: string;
      category: string;
      properties: string;
      code: string;
      shop_id: string;
      children: any;
      version_id: string;
    }
  ) {
    return kyServe
      .put(`myoracle/product`, {
        searchParams: {
          id,
        },
        json: {
          ...data,
          user_id: cookie.load("user")?.id ?? "",
        },
      })
      .json();
  }
  /** 知识库获取商品 */
  getProduct(page_num, page_size, version_id) {
    return kyServe
      .get(`myoracle/product`, {
        searchParams: {
          page_num,
          page_size,
          version_id,
          pid: "",
          user_id: cookie.load("user")?.id ?? "",
        },
      })
      .json();
  }
  /** 知识库获取商品分页 */
  getProductPage(item_id, title, attributes, page_num, page_size,) {
    return kyServe
      .post(`myoracle/product/search?page_num=${page_num}&page_size=${page_size}`, {
        json: {
          item_id,
          title,
          attributes
        },
      })
      .json();
  }
  // 获取进店商品
  getProductMasterPage(data, page_num, page_size,) {
    return kyServe
      .post(`myoracle/product/master/search?page_num=${page_num}&page_size=${page_size}`, {
        json: {
          item_id_or_title: data
        },
      })
      .json();
  }
  // 获取订单商品
  getProductSubPage(data, page_num, page_size,) {
    return kyServe
      .post(`myoracle/product/sub/search?page_num=${page_num}&page_size=${page_size}`, {
        json: {
          item_id_or_title: data
        },
      })
      .json();
  }
  /** 知识库添加店铺 */
  addShop(shop_name: string, version_id, children) {
    return kyServe
      .post(`myoracle/shop`, {
        json: {
          shop_name,
          version_id,
          children,
          user_id: cookie.load("user")?.id ?? "",
        },
      })
      .json();
  }
  /** 知识库删除店铺 */
  deleteShop(id: string, version_id) {
    return kyServe
      .delete(`myoracle/shop`, {
        searchParams: {
          id,
          version_id,
        },
      })
      .json();
  }
  /** 知识库编辑店铺 */
  updateShop(id: string, shop_name: string, version_id, children) {
    return kyServe
      .put(`myoracle/shop`, {
        searchParams: {
          id,
        },
        json: {
          shop_name,
          version_id,
          children,
          user_id: cookie.load("user")?.id ?? "",
        },
      })
      .json();
  }
  /** 知识库获取店铺 */
  getShop(page_num, page_size, version_id) {
    return kyServe
      .get(`myoracle/shop`, {
        searchParams: {
          page_num,
          page_size,
          version_id,
          user_id: cookie.load("user")?.id ?? "",
        },
      })
      .json();
  }
  /** 全局搜索 */
  search_all(data: {
    search: string;
    assistant_id: string;
    run_id: string;
    thread_id: string;
    message_id: string;
    conversation_id: string,
    communication_id: string,
  }) {
    return kyServe
      .post(`debug/search_all/messages`, {
        json: {
          ...data,
          user_id: cookie.load("user")?.id ?? "",
        },
      })
      .json();
  }
  /** 设置重跑从属id */
  setMessageChildId({ pmsg_id, cmsg_id }) {
    return kyServe
      .post(`debug/message/child`, {
        searchParams: {
          pmsg_id,
          cmsg_id,
        },
      })
      .json();
  }

  /** 消息重跑 */
  messageRetryCreate({ assistant_id, thread_id, message_ids }) {
    return msgServe
      .get(`retryMessage`, {
        searchParams: {
          messageId: message_ids,
          // cmsg_id,
        },
        // json: {
        //   assistant_id,
        //   thread_id,
        //   message_ids,
        // },
      })
      .json();
  }

  messageRetryReply({ message_id }) {
    return msgServe
      .get(`getRetryReply`, {
        searchParams: {
          messageId: message_id,
          // cmsg_id,
        },
        // json: {
        //   assistant_id,
        //   thread_id,
        //   message_ids,
        // },
      })
      .json();
  }
  /** 回复消息推荐生成文件 */
  genResponse(assistant_id, metadata, version_id, kb_name) {
    const formData = new FormData();
    formData.append("assistant_id", assistant_id);
    formData.append("purpose", "assistants");
    formData.append("loader", "chat_query");
    formData.append("version_id", version_id);
    if (metadata) {
      for (const k of Object.keys(metadata)) {
        if (k !== "loader") formData.append(k, metadata[k]);
      }
    }

    return kyServe
      .post(`knowledge/gen_history`, {
        searchParams: {
          assistant_id,
          version_id,
          kb_name,
          q_or_a: false,
        },
        body: formData,
      })
      .json();
  }
  /** 添加阿根廷版本 */
  addAgentium({ version, name, user_id, assistant_id, meta_data }) {
    return kyServe
      .post(`workbench/agentium`, {
        json: {
          version,
          name,
          user_id,
          assistant_id,
          meta_data,
        },
      })
      .json();
  }
  /** 添加意图 */
  addIntention({ version, name, user_id, assistant_id, meta_data }) {
    return kyServe
      .post(`workbench/intentjson`, {
        json: {
          version,
          name,
          user_id,
          assistant_id,
          meta_data,
        },
      })
      .json();
  }
  /** 修改意图标题 */
  updateIntentionName({ id, name }) {
    return kyServe
      .put(`workbench/intentjson`, {
        searchParams: {
          id,
        },
        json: {
          name,
        },
      })
      .json();
  }
  /** 修改动作内容 */
  updateIntentionActs({ id, assistant_id, data }) {
    console.log(id, assistant_id, data)
    return kyServe
      .put(`workbench/intentjson/${id}/acts`, {
        searchParams: {
          assistant_id,
        },
        json: {
          ...data,
        },
      })
      .json();
  }

  /** 修改实体内容 */
  updateIntentionEntities({ id, assistant_id, data }) {
    return kyServe
      .put(`workbench/intentjson/${id}/entities`, {
        searchParams: {
          assistant_id,
        },
        json: {
          ...data,
        },
      })
      .json();
  }
  /** 修改属性内容 */
  updateIntentionAttr({ id, assistant_id, data }) {
    return kyServe
      .put(`workbench/intentjson/${id}/attr`, {
        searchParams: {
          assistant_id,
        },
        json: {
          ...data,
        },
      })
      .json();
  }
  /** 删除属性内容 */
  deleteIntentionAttr({ id, assistant_id, data }) {
    return kyServe
      .delete(`workbench/intentjson/${id}/attr`, {
        searchParams: {
          assistant_id,
        },
        json: {
          ...data,
        },
      })
      .json();
  }
  /** 新增属性内容 */
  addIntentionAttr({ id, assistant_id, data }) {
    return kyServe
      .post(`workbench/intentjson/${id}/attr`, {
        searchParams: {
          assistant_id,
        },
        json: {
          ...data,
        },
      })
      .json();
  }
  /** 修改维度内容 */
  updateIntentionQualify({ id, assistant_id, data }) {
    return kyServe
      .put(`workbench/intentjson/${id}/qualify`, {
        searchParams: {
          assistant_id,
        },
        json: {
          ...data,
        },
      })
      .json();
  }
  /** 删除维度内容 */
  deleteIntentionQualify({ id, assistant_id, data }) {
    return kyServe
      .delete(`workbench/intentjson/${id}/qualify`, {
        searchParams: {
          assistant_id,
        },
        json: {
          ...data,
        },
      })
      .json();
  }
  /** 新增维度内容 */
  addIntentionQualify({ id, assistant_id, data }) {
    return kyServe
      .post(`workbench/intentjson/${id}/qualify`, {
        searchParams: {
          assistant_id,
        },
        json: {
          ...data,
        },
      })
      .json();
  }
  /** 获取阿根廷配置 */
  getIntention({ page_num, page_size, assistant_id }) {
    return kyServe
      .get(`workbench/intentjson`, {
        searchParams: {
          page_num,
          page_size,
          assistant_id,
        },
      })
      .json();
  }
  /** 获取阿根廷配置 */
  getIntentionTree({ page_num, page_size, assistant_id }) {
    return kyServe
      .get(`workbench/intentjson/tree`, {
        searchParams: {
          page_num,
          page_size,
          assistant_id,
        },
      })
      .json();
  }
  /** 添加阿根廷标题 */
  updateAgentiumName({ id, name }) {
    return kyServe
      .put(`workbench/agentium`, {
        searchParams: {
          id,
        },
        json: {
          name,
        },
      })
      .json();
  }
  /** 获取阿根廷配置 */
  getAgentium({ page_num, page_size, assistant_id }) {
    return kyServe
      .get(`workbench/agentium`, {
        searchParams: {
          page_num,
          page_size,
          assistant_id,
        },
      })
      .json();
  }
  /** 添加助手配置 */
  addMyqameta({ version, name, user_id, assistant_id, meta_data }) {
    return kyServe
      .post(`workbench/myqameta`, {
        json: {
          version,
          name,
          user_id,
          assistant_id,
          meta_data,
        },
      })
      .json();
  }
  /** 编辑助手配置标题 */
  updateMyqametaName({ id, name }) {
    return kyServe
      .put(`workbench/myqameta`, {
        searchParams: {
          id,
        },
        json: {
          name,
        },
      })
      .json();
  }
  /** 获取助手配置 */
  getMyqameta({ page_num, page_size, assistant_id }) {
    return kyServe
      .get(`workbench/myqameta`, {
        searchParams: {
          page_num,
          page_size,
          assistant_id,
        },
      })
      .json();
  }

  /** 添加回话点踩信息 */
  getTarget({ user_id, assistant_id, date_str }) {
    return kyServe
      .post(`workbench/index`, {
        searchParams: {
          date_str,
        },
        json: {
          user_id,
          assistant_id,
        },
      })
      .json();
  }
  /** 保存意图id */
  saveIntentIds({ thread_id, intent_ids }) {
    return kyServe
      .post(`debug/thread/intent_ids`, {
        searchParams: {
          thread_id,
        },
        json: {
          intent_ids,
        },
      })
      .json();
  }
  /** 获取默认意图id  */
  getDefaultIntentIds(thread_ids) {
    return kyServe
      .post(`debug/thread/intent_ids`, {
        json: {
          thread_ids,
        },
      })
      .json();
  }
  /** 获取助手每日数据信息  */
  getThumbData({
    source,
    assistant_id,
    thumb,
    start_time,
    end_time,
    conversationId,
    messageId,
    pageNum,
    pageSize,
    search,
    uid
  }) {
    return msgServe
      .post(`downloadConversationCSV`, {
        json: {
          source,
          assistantId: assistant_id,
          thumbs: thumb,
          startTime: start_time,
          endTime: end_time,
          conversationId,
          messageId,
          pageNum,
          pageSize,
          search,
          uid
        },
      })
      .json();
  }

  /** 根据点踩信息创建批量对话文件  */
  createBatchFile(data, is_all, assistant_id) {
    return kyServe
      .post(`batch/make-query`, {
        json: {
          data_json: data,
          is_all,
          assistant_id,
        },
      })
      .json();
  }
  /** 获取助手每日每位客服数据信息  */
  getCustomerData({ date_str, assistant_id }) {
    return kyServe
      .get(`workbench/customer_service_index`, {
        searchParams: {
          date_str,
          assistant_id,
        },
      })
      .json();
  }
  /** 历史语料清洗 */
  historyCleanse({ file_name, file_location, meta_data }) {
    return kyServe
      .post(`batch/history`, {
        json: {
          file_name,
          file_location,
          meta_data: JSON.parse(meta_data),
        },
      })
      .json();
  }

  /** 全量意图问法生成 */
  createHistoryQuery({ version_id, kb_name, assistant_id, metadata }) {
    return kyServe
      .post(`knowledge/create_history_query`, {
        json: {
          metadata,
          kb_name,
          version_id,
          assistant_id,
          q_or_a: true,
        },
      })
      .json();
  }

  /** 历史语料直接生成message */
  messagesCreate({ assistant_id, his_file_location }) {
    return kyServe
      .post(`debug/messages_create`, {
        json: {
          assistant_id,
          his_file_location,
        },
      })
      .json();
  }

  /** 全量意图回复生成 */
  createHistoryResponse({ version_id, kb_name, assistant_id, metadata }) {
    return kyServe
      .post(`knowledge/create_history_query`, {
        json: {
          q_or_a: false,
          metadata,
          kb_name,
          version_id,
          assistant_id,
        },
      })
      .json();
  }

  /** 获取质检文件 */
  getSeeFile(assistant_id) {
    return kyServe
      .get(`ses/file`, {
        searchParams: {
          assistant_id,
        },
      })
      .json();
  }

  /** 删除质检文件 */
  deleteSeeFile(id) {
    return kyServe
      .delete(`ses/file`, {
        searchParams: {
          id,
        },
      })
      .json();
  }

  /** 下载质检文件 */
  downSeeFile(id) {
    return kyServe.get(`ses/file/${id}`).blob();
  }

  /** 替换质检文件 */
  updateSeeFile(file, id) {
    const formData = new FormData();
    formData.append("file", file);
    return kyServe
      .put(`ses/file`, {
        body: formData,
        searchParams: {
          id,
        },
      })
      .json();
  }

  /** 上传质检文件 */
  uploadSeeFile(file, assistant_id) {
    const formData = new FormData();
    formData.append("file", file);
    return kyServe
      .post(`ses/file`, {
        searchParams: { assistant_id },
        body: formData,
      })
      .json();
  }
  getSeeFileContent({ file_id, client }) {
    return kyServe
      .post(`ses/fileContent`, {
        json: {
          file_id,
          client,
        },
      })
      .json();
  }
  deleteSeeFileContent(file_id) {
    return kyServe
      .delete(`ses/fileContent`, {
        json: {
          file_id,
        },
      })
      .json();
  }
  getSeeTask(assistant_id) {
    return kyServe
      .get(`ses/task`, {
        searchParams: {
          assistant_id,
        },
      })
      .json();
  }
  createSeeTask(data, meta_data) {
    const json = {
      ...data,
      meta_data: meta_data,
    };
    delete json.config;
    return kyServe
      .post(`ses/task`, {
        json: json,
      })
      .json();
  }
  deleteSeeTask(task_id) {
    return kyServe
      .delete(`ses/task`, {
        json: {
          task_id,
        },
      })
      .json();
  }

  getSeeChatList(task_id, client_name?: string) {
    return kyServe
      .get(`ses/result`, {
        searchParams: client_name
          ? {
            task_id,
            client_name,
          }
          : {
            task_id,
          },
      })
      .json();
  }

  downSeeResultFile(task_id) {
    return kyServe.post(`ses/result`, {
      json: {
        task_id,
      },
    });
  }

  getMessagesItem(id) {
    return kyServe.get(`debug/messages/${id}`).json();
  }

  getAgentPackage({ assistant_id, plat_form, version }) {
    return kyServe
      .get(`workbench/agent_package`, {
        searchParams: version
          ? { assistant_id, plat_form, version }
          : {
            assistant_id,
            plat_form,
          },
      })
      .json();
  }

  getSesIndex(assistant_id, version) {
    return kyServe
      .get(`ses/index`, {
        searchParams:
          typeof version !== "undefined"
            ? {
              assistant_id,
              version,
            }
            : {
              assistant_id,
            },
      })
      .json();
  }

  additionalDown(kb_name) {
    return kyServe
      .post(`myoracle/additional/additional_down`, {
        searchParams: {
          kb_name,
          user_id: cookie.load("user")?.id ?? "",
        },
      })
      .blob();
  }

  additionalImportdb(kb_name, file) {
    const formData = new FormData();
    formData.append("files", file);
    formData.append("user_id", cookie.load("user")?.id ?? "");
    formData.append("kb_name", kb_name);
    return kyServe
      .post(`myoracle/additional/import_db`, {
        body: formData,
      })
      .json();
  }

  setSesIndexs(data) {
    return kyServe
      .post(`ses/index/all`, {
        json: {
          indexs: data,
        },
      })
      .json();
  }
  updateSesIndexs(data) {
    return kyServe
      .put(`ses/index/all`, {
        json: {
          indexs: data,
        },
      })
      .json();
  }
  myoracleTaskStatus(assistant_id: string) {
    return kyServe
      .get(`myoracle/task`, {
        searchParams: {
          assistant_id,
        },
      })
      .json();
  }

  // 聚类意图
  kmeansIntention(data: {
    version_id: string;
    kb_name: string;
    assistant_id: string;
  }) {
    return kyServe
      .post(`knowledge/kmeans`, {
        searchParams: data,
      })
      .json();
  }

  // 新增意图问法推荐
  myoracleLlmknowledge(task_id: string) {
    return kyServe
      .get(`myoracle/llm_knowledge`, {
        searchParams: {
          task_id,
        },
      })
      .json();
  }

  // 标记推荐
  mackLlmknowledge(data: {
    intent_id: string;
    is_done: boolean;
    know_id: string;
    id: string;
  }) {
    const { is_done, intent_id, know_id, id } = data;
    return kyServe
      .put(`myoracle/llm_knowledge`, {
        searchParams: {
          id,
        },
        json: {
          is_done,
          intent_id,
          know_id,
        },
      })
      .json();
  }

  platformDiff() {
    return kyServe
      .get(`myoracle/platform/diff`, {
        searchParams: {
          platform: "pdd",
          data_type: "product",
        },
      })
      .json();
  }

  getOrganizations() {
    return kyServe.get(`organizations`).json();
  }

  platformTask(assistant_id: string) {
    return kyServe
      .post(`application/platform_task`, {
        json: {
          task_name: "product",
          user_id: cookie.load("user")?.id ?? "",
          assistant_id,
          task_type: "get",
          task_belong: "product",
          status: "undo",
          metadata: {},
        },
      })
      .json();
  }

  platformAuto(assistant_id) {
    return kyServe
      .get(`myoracle/platform/auto`, {
        searchParams: {
          platform: "pdd",
          data_type: "product",
          assistant_id,
        },
      })
      .json();
  }
  assistantsAdmin({ user_id, org_id }) {
    return kyServe
      .post(`haofubao/admin`, {
        json: {
          user_id,
          org_id,
        },
      })
      .json();
  }

  platformChatTask(data: {
    task_name: string;
    mall_id: string;
    mall_name: string;
    customer_id: string; // uid
    assistant_id: string;
    user_id: string;
    meta_data: {
      conversation_id: string; // thread id
    };
  }) {
    return kyServe
      .post("application/platform_task", {
        json: {
          status: "undo",
          task_belong: "chat",
          task_type: "send",
          ...data,
        },
      })
      .json();
  }
  getMasterUser(secret_key) {
    return userServe
      .get("users/current_master_user", {
        headers: {
          Authorization: `Bearer ${secret_key}`,
        }
      })
      .json();
  }
  //用户登录
  userLogin(username, password) {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);

    return userServe
      .post("myqaweb/login", {
        body: formData
      }).json();

  }
  //退出登录
  userLoginOut() {
    return userServe
      .post("logout", {}).json();

  }

  getFileUrl(name) {
    return kyServe.get(`workbench/upload/presigned_url?file_name=${name}`).json();
  }
  //更新催拍催付配置
  updateHurryConfig(data) {
    console.log(data)
    return msgConfigServe.put("config", {
      json: {
        ...data,
      },
    }).json();
  }
  //获取催拍催付配置
  hurryConfig() {
    return msgConfigServe.get("config").json();
  }
}

export const api = new API();